import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import Container from "../components/container";
import Icon from "../components/icon";
import PortableText from "../components/portableText";

import * as styles from "../styles/components/thank-you.module.css";

export const query = graphql`
  query ThankYouContactPageQuery {
    thankYou: sanityThankYouContact {
      title
      _rawContent
      button {
        blank
        secondaryButton
        text
        url
        document {
          asset {
            originalFilename
            url
          }
        }
      }
    }
  }
`;

const ThankYouPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const thankYou = (data || {}).thankYou;

  return (
    <Layout>
      <Seo title="Thank You" />
      <div className={styles.root}>
        <Container>
          {thankYou?.title && (<h1 className={styles.title}>{thankYou.title}</h1>)}
          {thankYou?._rawContent && (<div className={cn("entry-content", styles.content)}><PortableText blocks={thankYou._rawContent} /></div>)}
          {thankYou?.button && (thankYou?.button.url || (thankYou?.button.document && thankYou?.button.document.asset)) && (
            <>
              {thankYou.button.document && thankYou.button.document.asset ? (
                <a href={thankYou.button.document.asset.url + "/" + thankYou.button.document.asset.originalFilename} className={cn("btn", thankYou.button.secondaryButton && "secondary", styles.btn)} target="_blank" rel="noreferrer">
                  {thankYou.button.text}
                  <Icon symbol="arrow-right" />
                </a>
              ) : (
                <>
                  {thankYou.button.blank ? (
                    <a href={thankYou.button.url} className={cn("btn", thankYou.button.secondaryButton && "secondary", styles.btn)} target="_blank" rel="noreferrer">
                      {thankYou.button.text}
                      <Icon symbol="arrow-right" />
                    </a>
                  ) : (
                    <Link to={thankYou.button.url} className={cn("btn", thankYou.button.secondaryButton && "secondary", styles.btn)}>
                      {thankYou.button.text}
                      <Icon symbol="arrow-right" />
                    </Link>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
